<template>
    <section class="pt-3">
        <tabla-general :data="dataTable" alto="calc(100vh - 305px)" class-header="text-general f-16" @filaFuncion="openDetalle">
            <template slot="cabecera-izquierda">
                <div class="col-auto px-4 text-general f-18 f-600">
                    Registro de las comisiones pagadas
                </div>
                <div class="col d-flex">
                    <div class="btn-outline position-relative mx-2 f-14 px-2" @click="irBuscarPedido">
                        Buscar Pedido
                    </div>
                    <div v-if="$can('boton_tesoreria_exportar_comisiones_pagadas')" class="btn-general position-relative mx-2 f-14 px-2" @click="irExportar">
                        Exportar tabla
                    </div>
                    <div class="bg-light-f5 ml-auto border cr-pointer d-middle px-2 br-20 text-general position-relative" @click="openFiltro">
                        <div class="rounded-circle bg-general position-absolute" style="width:10px;height:10px;right:-2px;top:-5px;" />
                        <i class="icon-filter-outline f-16" /> Filtrar
                    </div>
                </div>
            </template>
            <el-table-column label="Fecha liquidación" align="center" min-width="150">
                <template slot-scope="{row}">
                    <p class="text-general text-center">{{ row.created_at | helper-fecha('DD MMM Y, h:mm a') }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Pedidos" align="center" min-width="100">
                <template slot-scope="{row}">
                    <p class="text-general text-center">{{ row.total_pedidos_comisionados }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="lechero" :label="$config.vendedor" align="center" min-width="150" />
            <el-table-column prop="cedis_nombre" label="Cedis" align="center" min-width="150" />
            <el-table-column label="Valor Comisión" align="center" min-width="150">
                <template slot-scope="{row}">
                    <p class="text-general text-center">{{ convertMoney(row.valor,row.idm_moneda) }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="encargado" label="Encargado" align="center" min-width="150" />
        </tabla-general>
        <!-- Partials -->
        <modal-filtro-comisiones ref="modalFiltroComisiones" :registros="registros" @filtrar="setTabla" />
        <modal-buscar-pedidos ref="modalBuscarPedidos" @verLiquidacion="irVerLiquidacionCompleta" />
        <modal-detalle-pago ref="modalDetallePago" />
        <modal ref="modalExportarTabla" titulo="Exportar tabla de pago de comisiones" no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <cargando v-if="cargando" />
                <div class="col-10 text-general text-center">
                    Indique el periodo a exportar
                </div>
                <div class="col-10 text-general mt-4">
                    <div class="block">
                        <p class="f-15 ml-3">Fecha</p>
                        <el-date-picker
                        v-model="fechas"
                        class="br-10"
                        type="daterange"
                        format="dd MMM yyyy"
                        value-format="yyyy-MM-dd"
                        align="right"
                        start-placeholder="Inicio"
                        end-placeholder="Fin"
                        @change="validarFechas"
                        />
                    </div>
                    <span v-if="noExisteDatosExcel" class="text-danger w-100 f-11">No se encontraron registros para el rango de fechas seleccionadas.</span>
                    <span v-if="rangoExcedido" class="text-danger w-100 f-11">No puede seleccionar un rango máximo a 15 días</span>
                </div>
                <div class="col-4 mt-3">
                    <div v-if="dataExcel.length > 0" class="btn-general f-14 px-3 ml-auto">
                        <vue-excel-xlsx
                        class="my-lg-2 boton-excel"
                        :data="dataExcel"
                        :columns="columnsExport"
                        :filename="'comisiones_pagadas'"
                        :file-type="'xlsx'"
                        :sheetname="'comisiones_pagadas'"
                        >
                            Exportar
                        </vue-excel-xlsx>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Liquidacion from '~/services/comisiones/liquidar'
import moment from 'moment'
import Vue from 'vue'
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx)

export default {
    components: {
        modalFiltroComisiones: () => import('./partials/modalFiltroComisiones'),
        modalDetallePago: () => import('./partials/modalDetallePago'),
        modalBuscarPedidos: () => import('./partials/modalBuscarPedidos')
    },
    data(){
        return{
            cargando: false,
            noExisteDatosExcel: false,
            rangoExcedido: false,
            dataTable: [],
            registros: [],
            fechas: [],
            dataExcel: [],
            columnsExport : [
                {
                    label: "Fecha liquidación",
                    field: "created_at",
                },
                {
                    label: "ID Leechero",
                    field: "id_tienda",
                },
                {
                    label: "Pedidos",
                    field: "total_pedidos_comisionados",
                },
                {
                    label: "Leechero",
                    field: "lechero",
                },
                {
                    label: "Cedis",
                    field: "cedis_nombre",
                },
                {
                    label: "Valor comisión",
                    field: "valor",
                },
                {
                    label: "Encargado",
                    field: "encargado",
                },
            ],
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(val){
            this.getTabla()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.getTabla()
        }
    },
    methods: {
        async getTabla(){
            try {
                const {data} = await Liquidacion.listaPagadas()
                this.registros = _.cloneDeep(data.registros)
                this.dataTable = data.registros
            } catch (e){
                this.error_catch(e)
            }
        },
        async validarFechas(){
            if(this.fechas == null || this.fechas.length < 1){
                return this.limpiarDatosExcel();
            }
            this.noExisteDatosExcel = false;
            let inicial = this.fechas[0];
            let final = this.fechas[1];
            let rangoDias = moment(inicial).add(15, 'days').format('YYYY-MM-DD');
            if(rangoDias < final){
                return this.rangoExcedido = true;
            }
            this.rangoExcedido = false;
            this.getExportarComisionesPagadas();
        },
        async getExportarComisionesPagadas(){
            try {
                if(this.fechas == null || this.fechas.length < 1){
                    return this.limpiarDatosExcel();
                }
                this.limpiarDatosExcel();
                this.cargando = true;
                const params = {
                    fechaInicio: this.fechas[0],
                    fechaFin: this.fechas[1]
                };
                const {data} = await Liquidacion.getExportarComisionesPagadas(params)
                if(data.registros.length < 1)return this.noExisteDatosExcel = true;
                data.registros.map(element => {
                    element.created_at = moment(element.created_at).format("DD MMM Y, h:mm a");
                    element.valor = this.convertMoney(element.valor, element.idm_moneda);
                });
                this.dataExcel = data.registros;
                this.noExisteDatosExcel = false;
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false;
            }
        },
        irExportar(){
            this.noExisteDatosExcel = false;
            this.dataExcel = [];
            this.fechas = [];
            this.$refs.modalExportarTabla.toggle();
        },
        limpiarDatosExcel(){
            this.noExisteDatosExcel = false;
            this.dataExcel = [];
        },
        irBuscarPedido(){
            this.$refs.modalBuscarPedidos.toggle()
        },
        openDetalle(row){
            this.$refs.modalDetallePago.toggle(row.id)
        },
        openFiltro(){
            this.$refs.modalFiltroComisiones.toggle();
        },
        setTabla(data){
            this.dataTable = data
        },
        irVerLiquidacionCompleta(id){
            setTimeout(() => {
                this.$refs.modalDetallePago.toggle(id)
            },50);
        },
    }
}
</script>
<style scoped>
.boton-excel {
    border: 0px;
    background: transparent;
    color: #fff;
} 
</style>